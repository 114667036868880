import Slideout from 'slideout';

var slideout = new Slideout({
  'panel': document.getElementById('panel'),
  'menu': document.getElementById('menu'),
  'padding': 256,
  'tolerance': 70,
  'side': 'right'
});

document.querySelector('.nav-toggle').addEventListener('click', () => {
  slideout.toggle();
});
document.querySelector('.nav-close').addEventListener('click', () => {
  slideout.close();
});